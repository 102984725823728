











































































































































import { mdiClose } from '@mdi/js';
import Vue from 'vue';
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import LoginCard from '@/components/specific/LoginCard.vue';
import Log from '@/resources/plugins/Logger/Log';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';

const AuthService = ServiceFactory.get('auth');

export default Vue.extend({
  name: 'PasswordReset',
  components: { LoginCard },

  data(): {
    dialog: boolean;
    dialogTitle: string;
    disabled: boolean;
    icons: {
      [key: string]: string;
    };
    // loading: boolean;
    resetParams: {
      password: string;
      confirmPassword: string;
    };
    resetDone: boolean;
    showedPassword: {
      confirm: boolean;
      new: boolean;
    };
    loading: boolean;
  } {
    return {
      dialog: false,
      dialogTitle: '',
      disabled: false,
      icons: {
        mdiClose,
      },
      loading: false,
      resetParams: {
        password: '',
        // フォームの順番で定義する
        /* eslint-disable-next-line vue/sort-keys */
        confirmPassword: '',
      },
      // 配置順で定義
      /* eslint-disable-next-line vue/sort-keys */
      resetDone: false,
      showedPassword: {
        confirm: false,
        new: false,
      },
    };
  },
  computed: {
    ...UIWorkspaceMapper.mapState(['workspace']),
  },
  async created() {
    try {
      // ワンタイムセッションチェック
      const sessionId = sessionStorage.getItem('oneTimeSession') as string;
      const { workspaceId } = this.workspace;

      await AuthService.checkSession({
        onetimeSession: sessionId,
        workspaceId,
      });
    } catch (error) {
      Log.error(error);
      switch (error.message) {
        case 'session disabled.':
          this.dialog = true;
          this.dialogTitle = 'このURLは無効です';
          this.disabled = true;
          break;
        default:
      }
    }
  },
  methods: {
    ...DomainAuthMapper.mapActions(['setToken', 'setUserAttributes']),
    ...UICommonMapper.mapActions(['setNavigating', 'setErrorMessage']),

    async setPassword() {
      const self = this;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      self.loading = true;

      try {
        const onetimeSession = sessionStorage.getItem('oneTimeSession') as string;
        const params = {
          password: self.resetParams.password,
        };

        await AuthService.updateUserOnetime(onetimeSession, params);

        // セッションIDを削除
        sessionStorage.removeItem('oneTimeSession');
        self.loading = false;
        self.resetDone = true;
      } catch (error) {
        self.loading = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },
  },

  validations() {
    return {
      resetParams: {
        // フォームの順番で定義する
        /* eslint-disable vue/sort-keys */
        password: {
          required,
          passwordComplexity: this.$$validators.passwordComplexity,
          minLength: minLength(8),
          maxLength: maxLength(32),
        },
        confirmPassword: {
          required,
          sameAs: sameAs(() => this.resetParams.password),
        },
        /* eslint-enable vue/sort-keys */
      },
    };
  },
});
